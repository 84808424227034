.menu-wrapper {
  display: flex;
  width: var(--group-wrapper-width);
}

.menu-left-side {
  justify-content: space-between;
  margin-right: 10px;
}
.menu-left-side-text {
  height: 30px;
  align-self: center;
  margin-right: 10px;
}
.menu-left-side-text a {
  text-decoration: none;
  color: var(--special-menu-color);
  font-weight: var(--special-menu-weight);
}
.left-container {
  display: flex;
}
.menu-right-side {
  padding-top: 5px;
  justify-content: flex-end;
  display: flex;
}
.menu-middle {
  flex: 1;
  margin: auto;
}
.menu-dropdown {
  position: relative;
  display: inline-block;
  margin-right: var(--loc-icon-right-margin);
}
/* Show the dropdown menu on hover */
.menu-dropdown:hover .menu-dropdown-content {
  display: block;
  margin-left: -60px;
}

/* Dropdown Content (Hidden by Default) */
.menu-dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
/* Links inside the dropdown */
.menu-dropdown-content a {
  color: black;
  padding: 4px 4px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.menu-dropdown-content a:hover {
  background-color: #ddd;
}
/* For shopping cart */
.numberCircle {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  padding: 1px;
  background: rgb(8, 8, 8);
  border: 2px solid #666;
  color: #fff;
  text-align: center;
  font: 10px Arial, sans-serif;
}

.menu-dropdown-title {
  height: 24px;
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.5s;
}

.menu-dropdown-title a {
  color: #8a8a8a;
  text-decoration: none;
  transition: color 0.5s;
}

.menu-dropdown-title:hover {
  border-bottom: 1px solid black;
}

.menu-dropdown-title a:hover {
  color: black;
}

.menu-cart-wrapper {
  display: flex;
}
.menu-cart-icon {
  flex: 1;
}

.menu-mobile-home-img {
  margin-top: 5px;
  margin-right: var(--mobile-home-img-right);
}

.logo-wrapper {
  border: 1px solid lightblue;
  border-radius: 6px;
  background: -webkit-linear-gradient(lightblue, blue);
  width: 65px;
  height: 45px;
}

.menu-logo-wrapper {
  width: 33px;
  height: 23px;
}
.menu-logo-wrapper-bak {
  border: 1px solid lightblue;
  border-radius: 4px;
  background: -webkit-linear-gradient(lightblue, blue);
  width: 33px;
  height: 23px;
}

.menu-loc-icon {
  margin-right: 15px;
}

.search-box {
  width: 200px;
}
/* Change the background color of the dropdown button when the dropdown content is shown */
.menu-dropdown:hover .menu-dropbtn {
  background-color: #3e8e41;
}
/* Dropdown Button */
.menu-dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 20px;
  border: none;
}
@media (min-width: 700px) {
  .menu-dropdown {
    margin-right: 0px;
  }
  .menu-dropdown-content {
    min-width: 220px;
  }
  .menu-dropdown-content a {
    padding: 8px 8px;
  }
  .menu-wrapper {
    align-content: stretch;
    padding-top: 10px;
  }
  .menu-left-side {
    margin-left: 0px;
  }
  .menu-right-side {
    flex: 1;
    padding-top: 5px;
  }
  .menu-wrapper > .menu-right-side > div {
    margin: 8px;
    font-size: 1em;
  }
  .menu-logo-wrapper {
    border-radius: 6px;
    width: 71px;
    height: 45px;
    margin-left: 0px;
  }
}

@media (max-width: 699px) {
  input {
    border-radius: 1;
  }
  input[type="search"] {
    height: 26px;
    width: var(--mobile-search-width);
    -webkit-appearance: none;
  }
}
