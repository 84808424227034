.sum-container {
  margin: 0px 0px 10px 0px;
  width: var(--group-wrapper-width);
}

.sum-wrapper {
  display: flex;
  flex-direction: column;
}

/* wrapper for order summary */
.sum-ordersum-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
/* wrapper for delivery address */
.sum-addr-wrapper {
  margin: 15px 10px 0px 2px;
  text-align: left;
  /*font-family: Arial, Helvetica, sans-serif;*/
  align-items: flex-start;
  display: flex;
}

/* grid container for 2 columns table */
.sum-tbl-container {
  display: grid;
  grid-template-columns: max-content max-content;
  padding: 0px;
}
/* table item left aligned */
.sum-tbl-item-left {
  border: 0px;
  text-align: left;
  padding: 0px 15px 4px 0px;
  margin-left: 3px;
  font-family: Arial, Helvetica, sans-serif;
}
/* table item right aligned */
.sum-tbl-item-right {
  border: 0px;
  text-align: right;
  padding-bottom: 4px;
  font-family: Arial, Helvetica, sans-serif;
}

/* shopping cart table container 2/3 columns */
.sum-cart-container {
  display: grid;
  grid-template-columns: max-content max-content;
  padding: 2px;
  text-decoration: none;
}
.sum-cart-item {
  border: 0px;
  text-align: left;
  padding: 2px 15px 15px 2px;
  text-decoration: none;
}

.sum-title-div {
  padding: 10px 10px 5px 0px;
  text-align: left;
  /*font-family: Arial, Helvetica, sans-serif;*/
}

.popup {
  margin: 10px;
}

.sum-tariff-popup-btn {
  border: none;
  color: orange;
  background-color: white;
  font-size: 16px;
  justify-self: start;
  padding-left: 0px;
}

.sum-payment-wrapper {
  border: 1px solid black;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin-top: 20px;
}
.sum-payment-title {
  padding: 5px 10px 10px 10px;
}

.payment-sep {
  width: var(--group-wrapper-width);
}

.sum-btn-div {
  margin-top: 10px;
  margin-bottom: 20px;
}

.sum-pickup-loc {
  margin-top: 15px;
  margin-bottom: 15px;
}
.sum-pickup-loc a {
  text-decoration: none;
}
.order-btn {
  background-color: orangered;
  color: white;
  padding: 5px 25px;
  font-size: 15px;
}
.disabled-order-btn {
  background-color: grey;
  color: white;
  padding: 5px 25px;
  font-size: 15px;
}
.flex-b10 {
  display: flex;
  margin-bottom: 10px;
}
.flex-t10 {
  display: flex;
  margin-top: 10px;
}
.sum-tbl-item-leftt10 {
  border: 0px;
  text-align: left;
  padding: 10px 15px 4px 0px;
  margin-left: 3px;
  font-family: Arial, Helvetica, sans-serif;
}
.sum-tbl-item-rightt10 {
  border: 0px;
  text-align: right;
  padding-bottom: 4px;
  padding-top: 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.storeord-wrapper {
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 15px 0px 15px 0px;
  padding-left: 15px;
  max-width: 600px;
}

.storeord-top10b5 {
  margin-top: 10px;
  margin-bottom: 5px;
}
.payment-choice-container {
  display: grid;
  grid-template-columns: max-content;
  gap: 3px;
}
.pmtchoice-nonsel {
  width: var(--group-wrapper-width);
  background-color: rgb(250, 250, 250);
  padding: 6px 0px 6px 0px;
  border: solid black 1px;
}
.pmtchoice-nonsel:hover {
  background-color: rgb(111, 143, 202);
  cursor: pointer;
  color: white;
  font-weight: 500;
}
.pmtchoice-sel {
  width: var(--group-wrapper-width);
  padding: 6px 0px 6px 0px;
  border: solid black 1px;
}
.left10-top10 {
  margin-left: 10px;
  margin-top: 10px;
}
.flex-center {
  display: flex;
  align-items: center;
}
.progressive-container {
  max-width: 600px;
  margin-bottom: 10px;
}

@media (min-width: 700px) {
  .sum-container {
    margin: 10px 0px 10px 0px;
  }

  .sum-cart-container {
    padding: 10px;
  }
  .sum-ordersum-wrapper {
    margin-right: 40px;
  }
  .sum-addr-wrapper {
    margin: 10px 10px 0px 10px;
  }
  .sum-cart-item {
    padding: 2px 20px 20px 0px;
  }
  .sum-wrapper {
    flex-direction: row;
  }
  .popup {
    margin: 20px;
  }
  .sum-title-div {
    padding: 10px 10px 15px 0px;
  }
  .payment-sep {
    width: 400px;
  }
  .pmtchoice-sel {
    width: 600px;
  }
  .pmtchoice-nonsel {
    width: 600px;
  }
}
