.generic-wrapper {
  width: var(--group-wrapper-width);
  margin: 5px 0px 5px 0px;
}

.generic-flex {
  display: flex;
}
.generic-flex-l10 {
  display: flex;
  margin-left: 10px;
}
.left-10 {
  margin-left: 10px;
}
.left-15 {
  margin-left: 15px;
}
.right-5 {
  margin-right: 5px;
}
.width-50 {
  width: 50px;
}
.top-10 {
  margin-top: 10px;
}
.top-15 {
  margin-top: 15px;
}
.top-20 {
  margin-top: 20px;
}
.top-5 {
  margin-top: 5px;
}
.brightness {
  background-color: white;
  display: inline-block;
  margin-right: 5px;
}
.brightness img:hover {
  opacity: 0.5;
}

.small-btn {
  background-color: rgb(248, 118, 12);
  color: white;
  padding: 4px 8px;
  font-size: small;
}
.small-btn-disabled {
  background-color: darkgray;
  color: black;
  padding: 4px 8px;
  font-size: small;
}
.threecol-tbl-wrapper {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  column-gap: 20px;
  row-gap: 15px;
}
.fourcol-tbl-wrapper {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  column-gap: 20px;
  row-gap: 15px;
}
.small-blue-btn {
  background-color: rgb(142, 142, 230);
  color: white;
  padding: 3px 5px;
  font-size: small;
}
.small-green-btn {
  background-color: rgb(55, 109, 100);
  color: white;
  padding: 3px 5px;
  font-size: small;
}
.twocol-tbl {
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 10px;
  row-gap: 5px;
}
.nodec-div a {
  text-decoration: none;
}
.hr-200 {
  width: 200px;
  height: 1.2px;
  border-width: 0px;
  color: gray;
  background-color: gray;
}
.hr-240 {
  width: 240px;
  height: 1.2px;
  border-width: 0px;
  color: gray;
  background-color: gray;
}
.hr-300 {
  width: 300px;
  height: 1.2px;
  border-width: 0px;
  color: gray;
  background-color: gray;
}
.left-60 {
  margin-left: 60px;
}
.flex-wrapper-top {
  display: flex;
  align-items: flex-start;
}
.flex-wrapper-ctr {
  display: flex;
  align-items: center;
}
.twocol-form {
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 4px 15px;
}
.top10b15 {
  margin-top: 10px;
  margin-bottom: 15px;
}
.green-btn {
  background-color: rgb(39, 100, 39);
  color: white;
  padding: 4px 10px;
  font-size: 13px;
}
.disabled-green-btn {
  background-color: grey;
  color: white;
  padding: 4px 10px;
  font-size: 13px;
}
.left-20 {
  margin-left: 20px;
}
.lightgreen-btn {
  background-color: rgb(12, 158, 221);
  color: white;
  padding: 6px 12px;
  font-size: 13px;
}
.disabled-lightgreen-btn {
  background-color: grey;
  color: white;
  padding: 6px 12px;
  font-size: 13px;
}
.password-mask {
  -webkit-text-security: disc;
  -moz-webkit-text-security: disc;
  -moz-text-security: disc;
}
.alabel {
  color: blue;
  cursor: pointer;
}
.blue-link-label {
  border: 1px solid rgb(41, 41, 146);
  background-color: rgb(41, 41, 146);
  color: white;
  cursor: pointer;
  border-radius: 2px;
  max-width: 500px;
  padding: 4px 30px 6px 30px;
}
.choice-label:hover {
  background-color: rgb(111, 143, 202);
  cursor: pointer;
  color: white;
}
.disabled-blue-link-label {
  border: 1px solid gray;
  background-color: gray;
  color: white;
  border-radius: 2px;
  max-width: 500px;
  padding: 4px 30px 6px 30px;
}
.html-content-container {
  padding: 15px;
}
.green-link-label {
  border: 1px solid rgb(41, 146, 106);
  background-color: rgb(41, 146, 106);
  color: white;
  cursor: pointer;
  border-radius: 2px;
  max-width: 500px;
  padding: 4px 30px 6px 30px;
}
.disclaimer-container {
  margin-top: 10px;
  font-size: smaller;
}
@media (min-width: 700px) {
  .generic-wrapper {
    margin: 0px 0px 15px 0px;
  }
}
