.plv-wrapper {
  width: var(--group-wrapper-width);
  display: grid;
  grid-template-columns: repeat(var(--thumbnail-column-count), 1fr);
}

.plv-thumbnail-item {
  margin: 5px 5px 5px 0px;
  width: var(--thumbnail-item-width);
}
