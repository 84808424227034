/* grid container for 2 columns table */
.tbl-container2 {
  display: grid;
  grid-template-columns: max-content max-content;
  padding: 10px;
}
.tbl-container2m {
  display: grid;
  grid-template-columns: max-content max-content;
  padding: 5px 0px 0px 0px;
}
.tbl-container4 {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  padding: 10px 10px 10px 0px;
}

/* table item left aligned */
.tbl-item-left {
  border: 0px;
  text-align: left;
  padding: 2px 15px 2px 2px;
  font-family: Arial, Helvetica, sans-serif;
}
/* table item right aligned */
.tbl-item-right {
  border: 0px;
  text-align: right;
  padding: 2px;
  font-family: Arial, Helvetica, sans-serif;
}

/* bordered table with background */
.tbl-item-label {
  border-top: 1px solid #808080;
  border-left: 2px solid #808080;
  border-right: 1px solid #808080;
  text-align: left;
  padding: 2px 15px 2px 2px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: lightgray;
  padding: 5px 8px 5px 8px;
}
.tbl-item-value {
  border-top: 1px solid #808080;
  border-right: 2px solid #808080;
  text-align: left;
  padding: 2px 15px 2px 2px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #eeeeee;
  padding: 5px 8px 5px 8px;
}

.tbl-item-value:nth-last-child(1) {
  border-top: 1px solid #808080;
  border-right: 2px solid #808080;
  border-bottom: 2px solid #808080;
  text-align: left;
  padding: 2px 15px 2px 2px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #eeeeee;
  padding: 5px 8px 5px 8px;
}

.tbl-item-label:nth-last-child(2) {
  border-top: 1px solid #808080;
  border-left: 2px solid #808080;
  border-right: 1px solid #808080;
  border-bottom: 2px solid #808080;
  text-align: left;
  padding: 2px 15px 2px 2px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: lightgray;
  padding: 5px 8px 5px 8px;
}

.tbl-item-value:nth-child(2) {
  border-top: 2px solid #808080;
  border-right: 2px solid #808080;
  text-align: left;
  padding: 2px 15px 2px 2px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #eeeeee;
  padding: 5px 8px 5px 8px;
}

.tbl-item-label:nth-child(1) {
  border-top: 2px solid #808080;
  border-left: 2px solid #808080;
  border-right: 1px solid #808080;
  text-align: left;
  padding: 2px 15px 2px 2px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: lightgray;
  padding: 5px 8px 5px 8px;
}

.valign-tbl {
  vertical-align: text-top;
}

.progressive-container {
  margin-left: 10px;
  margin-top: 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: medium;
}
.tbl-progressive {
  margin-top: 8px;
  display: grid;
  grid-template-columns: max-content max-content;
  padding: 0px 5px 10px 5px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  row-gap: 12px;
}
.bottom-5 {
  margin-bottom: 5px;
}
.tbl-container3 {
  display: grid;
  grid-template-columns: max-content max-content;
  row-gap: 5px;
  padding: 10px;
}
