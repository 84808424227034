.loc-container {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin-right: 5px;
}

.loc-call-out {
  margin-bottom: 10px;
  align-content: flex-start;
}

.loc-call-out a {
  text-decoration: none;
}

.loc-title {
  margin-left: 20px;
}

.loc-row-container {
  display: flex;
}
.loc-row-right-item {
  flex: 1;
  align-self: start;
  margin-left: 15px;
}

.loc-row-right-item a {
  text-decoration: none;
}

@media (min-width: 700px) {
  .loc-container {
    margin-left: 15px;
    margin-right: 15px;
  }
}
