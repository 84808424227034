.sc-container {
  margin: 5px 0px 5px 0px;
  width: var(--group-wrapper-width);
}

.items-container {
  margin-left: 2px;
  margin-bottom: 20px;
}

.item-wrapper {
  margin: auto;
  display: flex;
}

.item-image {
  width: 100;
}

.item-desc {
  margin-left: 10px;
  margin-right: 5px;
  flex: 1;
}

.item-other {
  margin-top: 15px;
  padding-bottom: 20px;
  padding-right: 10px;
}

.cart-vt-wrapper {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 15px;
}
.cart-line {
  margin-left: 10px;
}

.inner-row-wrapper {
  display: grid;
  margin-left: 10px;
  grid-template-columns: max-content max-content max-content;
}

.qty-container {
  display: flex;
  width: 80px;
  margin: auto;
  border-radius: 6px;
  border: 1px solid black;
}

.qty-minus {
  border-right: 1px solid black;
  flex: 3;
}
.qty-minus a {
  text-decoration: none;
}

.qty-minus div {
  margin-top: 5px;
  font-size: 14pt;
}
.qty-minus:hover {
  background-color: red;
}

.qty-plus {
  flex: 3;
}
.qty-plus a {
  text-decoration: none;
}

.qty-plus div {
  margin-top: 5px;
  font-size: 14pt;
}
.qty-plus:hover {
  background-color: green;
}

.qty-item {
  border-right: 1px solid black;
  flex: 4;
  text-align: center;
}

.qty-item a {
  text-decoration: none;
}

.qty-item div {
  font-size: 14pt;
}

.sc-title {
  margin-left: 5px;
}

.sc-empty-wrapper {
  margin-bottom: 30px;
}
.threecol-tbl {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  column-gap: 10px;
  row-gap: 5px;
}
.nddiv a {
  text-decoration: none;
}
.nddiv-t10 {
  margin-top: 10px;
}
.nddiv-t10 a {
  text-decoration: none;
}
.left-40 {
  margin-left: 40px;
}
.bottom-15 {
  margin-bottom: 15px;
}
@media (min-width: 700px) {
  .items-container {
    margin-left: 10px;
  }
  .item-image {
    margin-left: 10px;
  }

  .item-desc {
    margin-right: 15px;
  }
  .sc-title {
    margin-left: 20px;
  }
  .sc-container {
    margin: 20px 0px 5px 0px;
  }
}

.cross-sign {
  font-size: 18px;
  transform: rotate(45deg);
  cursor: pointer;
}
.other-sign {
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  padding-bottom: 5px;
  padding-left: 5px;
  color: black;
}
