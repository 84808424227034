.generic-flex2 {
  display: flex;
  font-size: larger;
}

.bti-div {
  font-size: x-small;
}

.bti-item-list {
  /*font-size: x-small;*/
  margin-bottom: 12px;
}

.bti-cb {
  height: 16px;
}
.atc-btn-style {
  background-color: rgba(226, 95, 8, 0.89);
  color: white;
  padding: 3px 9px;
  margin-top: 6px;
}
.top-5 {
  margin-top: 5px;
}
.bt-btn-style {
  background-color: #3a4dae;
  color: white;
  padding: 3px 10px;
  font-size: small;
}
.bt-red-btn-style {
  background-color: red;
  color: white;
  padding: 3px 10px;
  font-size: small;
}
