.ft-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 25px 0px 10px 0px;
  width: var(--group-wrapper-width);
  border-top: 2px solid rgb(120, 120, 120);
}

.ft-container > div a {
  text-decoration: none;
  color: #505050;
}
.ft-group1 {
  flex: 1;
  align-self: flex-start;
  display: inline-block;
}

.ft-group2 {
  flex: 1;
  align-self: flex-start;
  display: inline-block;
}

.ft-group3 {
  flex: 1;
  align-self: flex-start;
}

.ft-title {
  margin-top: 5px;
  text-decoration: none;
}

.ft-link-grp {
  margin-top: 12px;
}

.ft-link-item {
  margin-bottom: 4px;
}

.ft-container > div a:hover {
  color: rgb(0, 183, 255);
}

@media (min-width: 700px) {
  .ft-container {
    margin: 25px 0px 20px 0px;
  }
  .ft-title {
    margin-top: 10px;
  }
}
