.search-result-container {
  margin: 10px 0px auto 0px;
  width: var(--group-wrapper-width);
}
.search-item-wrapper {
  display: flex;
}
.search-item-image {
  width: 150px;
  margin-right: 5px;
  margin-bottom: 15px;
}
.search-item-detail {
  flex: 1;
  margin-bottom: 15px;
}

@media (min-width: 700px) {
  .search-result-container {
    margin: 10px 0px auto 0px;
  }
  .search-item-image {
    width: 200px;
    margin-right: 20px;
  }
}
