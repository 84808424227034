.prod-thumbnail-wrapper {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  row-gap: 5px;
}
.prod-thumbnail-wrapper-mini {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content max-content;
  row-gap: 5px;
}
#soldOutImage {
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.large-nbox-wrapper {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  row-gap: 5px;
}
.brightness {
  background-color: white;
  display: inline-block;
  margin-right: 5px;
}
.brightness img:hover {
  opacity: 0.5;
}
.brightness-selected {
  background-color: white;
  display: inline-block;
  margin-right: 5px;
  border: 1px solid red;
}
.selected {
  border: 1px solid red;
}
.varname-box {
  border: 1px solid darkgray;
  margin-right: 5px;
  padding: 3px;
  cursor: pointer;
  background-color: rgb(246, 250, 252);
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: 400;
}
.varname-box-selected {
  border: 1px solid red;
  margin-right: 5px;
  padding: 3px;
  cursor: pointer;
  background-color: beige;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: 550;
}
.varname-box-nfs {
  border: 1px dotted darkgray;
  margin-right: 5px;
  padding: 3px;
  cursor: pointer;
  color: gray;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: 400;
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  );
}
.varname-box-nfs-selected {
  border: 0.5px solid red;
  margin-right: 5px;
  padding: 3px;
  cursor: pointer;
  color: gray;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: 540;
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  );
}

.varnamef-box {
  border: 1px solid darkgray;
  margin-right: 5px;
  padding: 3px;
  cursor: pointer;
  background-color: rgb(246, 250, 252);
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.varnamef-box-selected {
  border: 1px solid red;
  margin-right: 5px;
  padding: 3px;
  cursor: pointer;
  background-color: rgb(246, 250, 252);
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: 550;
  display: flex;
  align-items: center;
}
.varnamef-box-nfs {
  border: 1px dotted darkgray;
  margin-right: 5px;
  padding: 3px;
  cursor: pointer;
  color: gray;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.varnamef-box-nfs-selected {
  border: 0.5px solid red;
  margin-right: 5px;
  padding: 3px;
  cursor: pointer;
  color: gray;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: 540;
  display: flex;
  align-items: center;
}

.bottom5 {
  margin-bottom: 5px;
}

.brightness0 {
  background-color: white;
  display: inline-block;
}
.brightness0 img:hover {
  opacity: 0.5;
}
.brightness3 {
  background-color: white;
  display: inline-block;
  margin-left: 3px;
}
.brightness3 img:hover {
  opacity: 0.5;
}
.brightness0b {
  background-color: white;
  display: inline-block;
  border-style: solid;
  border-width: 1px;
  border-color: red;
}
.brightness0b img:hover {
  opacity: 0.5;
}
.brightness3b {
  background-color: white;
  display: inline-block;
  margin-left: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: red;
}
.brightness3b img:hover {
  opacity: 0.5;
}
@media (min-width: 700px) {
  .prod-thumbnail-wrapper {
    grid-template-columns: max-content max-content max-content max-content max-content;
  }
  .prod-thumbnail-wrapper-mini {
    grid-template-columns: max-content max-content max-content max-content max-content max-content max-content max-content max-content;
  }
  .large-nbox-wrapper {
    display: grid;
    grid-template-columns: max-content max-content max-content max-content;
    row-gap: 5px;
  }
}
