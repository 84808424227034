.layaway-msg-wrapper {
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  max-width: 700px;
  padding: 15px;
}
.layaway-btn {
  background-color: rgb(57, 16, 204);
  color: white;
  padding: 6px 20px;
  font-size: 15px;
}

.la-table-c5 {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content;
  grid-gap: 0px;
}
.la-item-hd {
  background-color: rgb(85, 189, 224);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
}
.la-item-ev {
  background-color: rgb(240, 240, 240);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
}
.la-item-od {
  background-color: rgb(220, 220, 220);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
}
.la-item-sum {
  background-color: rgb(170, 170, 170);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
}
.la-item-ev a {
  text-decoration: none;
}
.la-item-od a {
  text-decoration: none;
}
.la-item-hd a {
  text-decoration: none;
}
.bottom-25 {
  margin-bottom: 25px;
}
.flex-wrapper-t10 {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.left-40 {
  margin-left: 40px;
}
.topalign-flex {
  display: flex;
  align-self: start;
  align-items: flex-start;
}
.top-20 {
  margin-top: 20px;
}
.bottom-10 {
  margin-bottom: 10px;
}
.left-8b8 {
  margin-left: 8px;
  margin-bottom: 8px;
}
.la-table-c9 {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content max-content max-content max-content max-content;
  grid-gap: 0px;
}
.la-list-hd {
  background-color: rgb(79, 115, 128);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
}
.la-list-hd a {
  text-decoration: none;
  color: white;
}
.la-list-hds {
  background-color: rgb(79, 115, 128);
  border: 1px solid #fff;
  padding: 5px;
  text-align: center;
}
.la-list-hds a {
  text-decoration: none;
  color: white;
}
.la-list-hdr {
  background-color: rgb(79, 115, 128);
  border: 1px solid #fff;
  padding: 5px;
  text-align: right;
}
.la-list-hdr a {
  text-decoration: none;
  color: white;
}
.la-item-evr {
  background-color: rgb(240, 240, 240);
  border: 1px solid #fff;
  padding: 5px;
  text-align: right;
}
.la-item-odr {
  background-color: rgb(220, 220, 220);
  border: 1px solid #fff;
  padding: 5px;
  text-align: right;
}
.la-date-title {
  display: flex;
  margin: 15px 0px 8px 15px;
}
.la-view-upd {
  margin-left: 20px;
  font-size: small;
}
.la-table-c4 {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  grid-gap: 0px;
}
.la-act-hd {
  background-color: rgb(106, 121, 126);
  border: 1px solid #fff;
  padding: 5px;
  color: white;
  text-align: left;
}
.la-ord-hd {
  background-color: rgb(81, 91, 179);
  border: 1px solid #fff;
  padding: 5px;
  color: white;
  text-align: left;
}
.la-discount {
  background-color: rgb(216, 185, 185);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
}
.left-20t12 {
  margin-left: 20px;
  margin-top: 12px;
}
.la-item-ev-250 {
  background-color: rgb(240, 240, 240);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
  max-width: 250px;
}
.la-item-od-250 {
  background-color: rgb(220, 220, 220);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
  max-width: 250px;
}

.la-item-ev-300 {
  background-color: rgb(240, 240, 240);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
  max-width: 300px;
}
.la-item-od-300 {
  background-color: rgb(220, 220, 220);
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
  max-width: 300px;
}

.title-flex {
  display: flex;
  gap: 0px 150px;
  margin-bottom: 15px;
  align-items: center;
}

.title-flex a {
  text-decoration: none;
  color: darkgreen;
}

.la-sect-header {
  display: flex;
  gap: 0px 5px;
  margin-top: 15px;
  margin-bottom: 5px;
  align-items: center;
}
.la-sect-title {
  padding-bottom: 7px;
}

.invoice-links-wrapper {
  display: flex;
  align-items: center;
}
.invoice-links-wrapper a {
  text-decoration: none;
  color: rgb(52, 52, 230);
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.invoice-name {
  padding-bottom: 4px;
}
