.coi-table {
  font-size: smaller;
  border-collapse: collapse;
}
.coi-table th {
  background-color: #d0d0d0;
  border: 1px solid black;
  padding: 2px 0px 2px 0px;
}
.coi-table td {
  border: 1px solid black;
  padding: 1px 4px 1px 4px;
  text-decoration: none;
}
.coi-col2-table {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 2px;
}
.coi-flex {
  display: flex;
  align-items: center;
  margin-top: 0px;
}
