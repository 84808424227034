.payment-container {
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  margin-right: 0px;
  width: var(--group-wrapper-width);
}
.pmt-orderinfo-wrapper {
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.pmt-orderinfo-item {
  justify-content: flex-start;
  margin: 20px 10px 20px 10px;
}
.payment-line {
  justify-content: flex-start;
  margin: 10px 5px 20px 5px;
}

.pmt-title-div {
  text-decoration: underline;
}
.pmt-title-wrapper {
  margin-top: 10px;
  margin-bottom: 8px;
  display: flex;
  align-content: center;
}
.ssl-icon-div {
  margin-top: 3px;
  margin-left: 10px;
}
@media (min-width: 700px) {
  .payment-line {
    margin: 20px 10px 20px 10px;
  }
}
