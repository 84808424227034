.combo-image-wrapper {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
}

.width400 {
  width: 400px;
}

.right-5_sel {
  margin-right: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: red;
}
.combo-title {
  margin-bottom: 5px;
}
.TextWrap {
  float: left;
  margin: 0px 10px 10px 0px;
}
.plus-div {
  margin-left: 5px;
  margin-right: 5px;
}
@media (min-width: 700px) {
  .combo-image-wrapper {
    display: grid;
    grid-template-columns: max-content max-content max-content max-content max-content max-content max-content max-content;
  }
}
