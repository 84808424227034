.pickup-container {
  margin: 10px 5px 10px 0px;
}

.pickup-col-wrapper {
  display: flex;
  flex-direction: column;
}

.pickup-row-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  padding: 10px 10px 10px 0px;
  align-items: flex-start;
  grid-row-gap: 20px;
}

.pickup-row-wrapper > div a {
  text-decoration: none;
  color: rgb(109, 180, 84);
}

.pickup-last-div {
  margin-left: 20px;
}

.pickup-rb-button {
  margin: 0px;
  padding: 0px;
}

@media (min-width: 700px) {
  .pickup-row-wrapper {
    grid-template-columns: max-content max-content max-content;
  }
}
