.form-container {
  margin: 10px 0px 10px 0px;
  width: var(--group-wrapper-width);
}

.form-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  width: var(--group-wrapper-width);
}

.form-item-label {
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: left;
}
.form-item-value {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 10px;
  justify-self: start;
}

.form-item-label a {
  text-decoration: none;
}
.form-item-value a {
  text-decoration: none;
}

.form-wrapper-with-border {
  display: grid;
  grid-template-columns: max-content max-content;
  border: 1px solid black;
  padding: 5px 15px 5px 15px;
}

.top-10 {
  margin-top: 10px;
}
