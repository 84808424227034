.assembly-container {
  margin: 10px 0px 10px 0px;
  width: var(--group-wrapper-width);
}

.assembly-wrapper {
  display: flex;
  flex-direction: column;
}

.assembly-item {
  margin: 5px 10px 5px 10px;
  justify-content: flex-start;
}
.assembly-title {
  margin: 10px 10px 5px 10px;
}
