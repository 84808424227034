.prod-wrapper {
  width: var(--group-wrapper-width);
}

.prod-detail-container {
  margin-bottom: 2px;
}

.prod-detail-image {
  padding: 2px;
  margin-bottom: 10px;
  justify-content: flex-end;
}

.prod-detail-info {
  padding: 5px;
  margin-bottom: 10px;
  flex: 1;
}

.prod-tbl-container2 {
  display: grid;
  grid-template-columns: max-content max-content;
  color: #606060;
}

.prod-tbl-item-left {
  border: 0px;
  text-align: left;
  padding: 2px 5px 2px 2px;
  font-family: Arial, Helvetica, sans-serif;
}
.left0 {
  margin-left: 0px;
}
.left3 {
  margin-left: 3px;
}
.left0b {
  margin-left: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: red;
}
.left3b {
  margin-left: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: red;
}

.generic-flex-b {
  display: flex;
  align-items: center;
}
.somsg-div {
  margin-left: 10px;
}
.somsg-div a {
  text-decoration: none;
  color: darkblue;
}
.modal-image {
  width: var(--modal-image-width);
}
.bs-wrapper {
  display: flex;
  align-items: center;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: small;
  background-color: rgb(242, 242, 242);
}
.avl-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  color: #606060;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: small;
  background-color: rgb(248, 248, 248);
}
@media (min-width: 700px) {
  .prod-detail-container {
    display: flex;
    justify-content: space-between;
  }
  .prod-detail-image {
    padding: 10px 10px 10px 0px;
  }
  .prod-detail-info {
    padding: 20px;
  }
}
