.App {
  text-align: center;
}

div.ex1 {
  margin: 0 0 0 0;
  border: 0px solid #000000;
}

:root {
  --top-container-width: 1200px;
  --prodcat-column-count: 3;
  --info-wrapper-width: 900px;
  --info-wrapper-margin-left: 25px;
  --group-wrapper-width: 1170px;
  --slider-pre-color: gold;
  --slider-next-color: gold;
  --thumbnail-item-width: 150px;
  --thumbnail-column-count: 3;
  --special-menu-color: black;
  --special-menu-weight: normal;
  --loc-icon-right-margin: 30px;
  --mobile-home-img-right: 20px;
  --category-text-transform: uppercase;
  --hammenu-display-mode: block;
  --faq-answer-width: 1150px;
  --home-item-width: 100px;
  --mobile-search-width: 180px;
  --category-image-height: 24px;
  --modal-image-width: 500px;

  --pdf-page-width: 595px;
  --pdf-page-height: 842px;
  --pdf-margin-left: 22px;
  --pdf-margin-bottom: 65px;
  --pdf-margin-top: 20px;
  --pdf-head-gap: 160px;
  --pdf-shipto-left: 120px;
  --pdf-table-percent: 92%;

  --pdf-fs-reg-table: smaller;
  --pdf-fs-large-table: x-small;
  --pdf-fs-left-header: x-small;
  --pdf-fs-right-header: smaller;
  --pdf-fs-header2: smaller;
  --pdf-fs-note: smaller;
  --pdf-fs-footer: x-small;
}

.top-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  width: var(--top-container-width);
}

.top-wrapper {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-dots li.slick-active button:before {
  color: blue !important;
}
.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}

.btn-style {
  background-color: #3a4dae;
  color: white;
  padding: 5px 20px;
  font-size: 13px;
}
.disabled-btn-style {
  background-color: grey;
  color: white;
  padding: 5px 20px;
  font-size: 13px;
}
#soldOutImage {
  opacity: 0.4;
  filter: alpha(opacity=40);
}
