.oc-container {
  margin: 10px 0px 10px 0px;
  width: var(--group-wrapper-width);
}

.oc-store-item {
  margin-bottom: 5px;
  margin-right: 15px;
}
.oc-store-item a {
  text-decoration: none;
  color: orange;
}
.oc-store-item2 {
  margin-bottom: 15px;
  margin-right: 15px;
}

.oc-title {
  margin-left: 10px;
  margin-bottom: 5px;
  margin-right: 15px;
}

.oc-message {
  margin-left: 10px;
  margin-bottom: 25px;
  margin-right: 15px;
  max-width: 700px;
}

.oc-paid-wrapper {
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  max-width: 700px;
}

.oc-paid-desc {
  justify-content: flex-start;
  margin: 20px 10px 20px 10px;
}
.oc-paid-desc2 {
  justify-content: flex-start;
  margin: 5px 10px 20px 10px;
}
.order-done-btn {
  background-color: rgb(76, 157, 184);
  color: white;
  padding: 6px 40px;
  font-size: 15px;
}

.lease-complete-wrapper {
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  max-width: 700px;
}
.lease-info {
  margin: 10px 10px 15px 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: medium;
}
.lease-esign-container {
  border: 1px solid black;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 10px 15px 20px 15px;
  max-width: 700px;
}
