.prodbycat-wrapper {
  display: grid;
  grid-template-columns: repeat(var(--prodcat-column-count), 1fr);
  width: var(--group-wrapper-width);
}

.prodbycat-grid-item {
  margin: 5px 5px 5px 0px;
}

.prodbycat-page-wrapper {
  display: flex;
  width: var(--group-wrapper-width);
  justify-content: end;
}

.prodbycat-page-space {
  flex: 1;
}
.prodbycat-page-item {
  margin-left: 8px;
  margin-right: 8px;
}
.prodbycat-page-item a {
  text-decoration: none;
}
