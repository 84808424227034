.info-container {
  margin: 10px 0px 10px 0px;
  width: var(--group-wrapper-width);
}

.info-wrapper {
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: var(--info-wrapper-width);
  margin-left: var(--info-wrapper-margin-left);
  text-align: center;
}

.info-item {
  justify-content: flex-start;
  margin: 10px 10px 10px 10px;
}
.disclosure {
  text-align: left;
}
.info-hr {
  width: 70%;
}

.info-img {
  width: 70%;
}

.info-contact-container {
  margin: 10px 10px 10px 10px;
}
.info-contact-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
}

.info-contact-item-label {
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: left;
}
.info-contact-item-value {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 10px;
  justify-self: start;
}

.info-contact-row-container {
  display: flex;
  align-items: flex-start;
  width: var(--group-wrapper-width);
}

.info-contact-comments {
  padding-left: 0px;
  padding-bottom: 20px;
}
.info-cat-select {
  padding: 2px 5px 2px 5px;
}
.info-btn-style {
  background-color: #3a4dae;
  color: white;
  padding: 5px 20px;
  font-size: 13px;
}

/* for store contacts */
.info-vr-style {
  border-left: 1px solid #000;
  height: 150px;
  margin-top: 30px;
  margin-right: 20px;
}

.info-store-wrapper {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-top: 30px;
}

.info-contact-loc-item {
  margin-left: 3px;
  margin-bottom: 3px;
  padding-bottom: 3px;
}

.info-contact-loc-item a {
  text-decoration: none;
}
.info-contact-mobile-wrapper {
  margin-left: 3px;
  border-top: 1px solid black;
}

.info-content-wrapper {
  margin-left: 6px;
  margin-right: 8px;
  text-align: left;
}
.info-content-wrapper li {
  padding-bottom: 10px;
}
.info-paragraph {
  padding-bottom: 20px;
  align-content: center;
}
.info-paragraph a {
  text-decoration: none;
}
@media (min-width: 700px) {
  .info-wrapper {
    border-radius: 25px;
  }
  .info-contact-container {
    margin: 20px 20px 20px 15px;
  }
  .info-contact-loc-item {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .info-content-wrapper {
    margin-left: 20px;
    margin-right: 20px;
  }
}
