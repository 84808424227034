.pickup-container {
  margin: 10px 0px 10px 0px;
  width: var(--group-wrapper-width);
}

.pickup-title {
  margin-left: 10px;
  margin-bottom: 5px;
  margin-right: 15px;
}

.pickup-message {
  margin-left: 10px;
  margin-bottom: 25px;
  margin-right: 15px;
  max-width: 700px;
}

.pickup-wrapper {
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.pickup-desc {
  justify-content: flex-start;
  margin: 20px 10px 20px 10px;
}

.pickup-store-item {
  margin-bottom: 5px;
  margin-right: 15px;
}
.pickup-store-item a {
  text-decoration: none;
  color: orange;
}
.pickup-store-item2 {
  margin-bottom: 15px;
  margin-right: 15px;
}

.pickup-title {
  margin-left: 10px;
  margin-bottom: 5px;
  margin-right: 15px;
}
