.hm-container {
  width: var(--group-wrapper-width);
  margin-top: 10px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
}

.home-image {
  margin: 0 auto;
}

.home-image-siderow {
  display: flex;
}
.ver-prod {
  margin-left: 10px;
}

.hmprom-container-orig {
  margin-top: 10px;
  margin-bottom: 2px;
  display: flex;
}

.hmprom-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: var(--group-wrapper-width);
  margin-top: 10px;
  margin-bottom: 2px;
}

.hmprom-item {
  margin-left: 0px;
  margin-right: 15px;
  margin-bottom: 10px;
  flex: 1;
}

.hmviewed-container {
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
}

.banner {
  max-width: 900px;
  height: 35px;
}

.slick-prev:before {
  color: var(--slider-pre-color);
}
.slick-next:before {
  color: var(--slider-next-color);
}

@media (min-width: 700px) {
  .hmprom-container {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 699px) {
  .hmprom-item {
    width: var(--home-item-width);
  }
}
